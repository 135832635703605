// 
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$prefix: ar- !default;
$font-family-sans-serif: 'DM Sans', sans-serif !default;
$blockquote-mark-color: var(--#{$prefix}gray-400) !default;
$link-decoration: none !default;
$link-hover-decoration: underline !default;
$input-group-padding-y: 0rem !default;
$input-group-padding-x: 0rem !default;
$card-spacer-y: $spacer * 1.875 !default;
$card-spacer-x: $spacer * 1.875 !default;

$tooltip-max-width: 500px;
$carousel-bullet-size: 0.5rem !important;

$steps-number-inner-size: 2.5rem !default;
$steps-number-size: 3rem !default;

$white: #fff !default;
$gray-100: #F2F4F5 !default;
$gray-200: #EBEEF0 !default;
$gray-300: #D8DEE0 !default;
$gray-400: #C1CDD1 !default;
$gray-500: #A4B8C2 !default;
$gray-600: #738891 !default;
$gray-700: #45616D !default;
$gray-800: #1A4557 !default;
$gray-900: #07151A !default;
$black: #000 !default;

$primary: #163948 !default;
$secondary: $gray-100 !default;
$info: #3787B0 !default;
$success: #50B261 !default;
$warning: #FFB15C !default;
$danger: #ED5050 !default;
$light: $white !default;
$dark: $gray-900 !default;
