//
// Image hover effects utilities
// ----------------------------------------------------------


// Swap image

.swap-image {
  display: inline-block;
  position: relative;
  user-select: none;

  .swap-from,
  .swap-to {
    display: block;
    transition: opacity .25s ease-in-out;
  }

  .swap-to {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
  }

  &:hover, &.active {
    .swap-from { opacity: 0; }
    .swap-to { opacity: 1; }
  }
}


// Inside card / nav links.js

.card:hover > .swap-image,
.card.card-active > .swap-image,
.nav-link:hover > .swap-image,
.nav-link.active > .swap-image {
  .swap-from,
  .swap-to { transition-duration: .2s; }
  .swap-from { opacity: 0; }
  .swap-to { opacity: 1; }
}


// Zoom image effect

.zoom-effect-wrapper {
  position: relative;
  transform: translateZ(0);
  overflow: hidden;
}

.zoom-effect-img {
  display: block;
  transition: transform .4s ease-in-out;
}

.zoom-effect:hover .zoom-effect-img {
  transform: scale(1.05);
}


// Polygon shape avatar

.polygon-avatar {
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  clip-path: path('M44.2392 4.03005C47.6985 1.61004 52.3015 1.61004 55.7608 4.03005C57.9922 5.59105 60.7615 6.1797 63.4349 5.66124C67.5795 4.85747 71.7845 6.72967 73.9604 10.3475C75.364 12.6811 77.6545 14.3453 80.3076 14.959C84.4207 15.9105 87.5007 19.3311 88.0171 23.5212C88.3501 26.224 89.7657 28.6759 91.9398 30.3157C95.3104 32.8578 96.7328 37.2355 95.5002 41.2733C94.7051 43.8779 95.0011 46.6936 96.3203 49.0759C98.3654 52.7692 97.8843 57.347 95.116 60.5344C93.3303 62.5904 92.4554 65.283 92.6915 67.996C93.0577 72.2018 90.7562 76.1881 86.9307 77.974C84.4632 79.1259 82.5687 81.2299 81.681 83.8044C80.3048 87.7955 76.581 90.5011 72.3599 90.5766C69.6371 90.6253 67.0507 91.7768 65.1926 93.7677C62.312 96.854 57.8096 97.811 53.9228 96.1631C51.4156 95.1002 48.5844 95.1002 46.0772 96.1631C42.1904 97.811 37.688 96.854 34.8074 93.7677C32.9493 91.7768 30.3629 90.6253 27.6401 90.5766C23.419 90.5011 19.6952 87.7955 18.319 83.8044C17.4313 81.2299 15.5368 79.1259 13.0693 77.974C9.24381 76.1881 6.94232 72.2018 7.30846 67.996C7.54464 65.283 6.66974 62.5904 4.88405 60.5344C2.1157 57.347 1.63456 52.7692 3.67971 49.0759C4.99892 46.6936 5.29487 43.8779 4.4998 41.2733C3.26722 37.2355 4.68961 32.8578 8.06016 30.3157C10.2343 28.6759 11.6499 26.224 11.9829 23.5212C12.4993 19.3311 15.5793 15.9105 19.6924 14.959C22.3455 14.3453 24.636 12.6811 26.0396 10.3475C28.2155 6.72967 32.4205 4.85747 36.5651 5.66124C39.2385 6.1797 42.0078 5.59105 44.2392 4.03005Z');
}
