$bg-light-blue: #CCDCE3;
$bg-dark-blue: #255F78;
$bg-nude-lighter: #FFE8DB;
$bg-warm: #F7F1ED;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 6,
) !default;
$--ar-modal-width: 590px !default;
$mega-dropdown-column-width: 18rem !default;

$aspect-ratios: (
        "1x1": 100%,
        "4x3": calc(3 / 4 * 100%),
        "3x4": calc(4 / 3 * 100%),
        "5x6": calc(6 / 5 * 100%),
        "16x9": calc(9 / 16 * 100%),
        "9x16": calc(16 / 9 * 100%),
        "21x9": calc(9 / 21 * 100%)
) !default;

$enable-dark-mode: false;

@import '../../node_modules/react-toastify/dist/ReactToastify.min.css';
@import "../../node_modules/flatpickr/dist/flatpickr.min.css";
@import "../../node_modules/swiper/modules/pagination";
@import "../../node_modules/swiper/swiper";
@import "../../node_modules/swiper/modules/effect-fade.min.css";
@import "../../node_modules/lightgallery/scss/lightgallery";
@import "../../node_modules/lightgallery/scss/lg-video";
@import "around/theme";
@import "./app.scss";
@import "./interweave.scss";
@import "./toastify.scss";
@import "./react-tel.scss";
@import "./custom/fullcalendar.scss";
@import "./custom/noui.scss";
@import "./custom/doctor-call.scss";
@import "./custom/components/footer.scss";
@import "./custom/anesthesiologist-form.scss";
@import "./custom/pdf.scss";

//:root {
//  --swiper-pagination-color: #{$primary};
//  --swiper-pagination-bullet-inactive-color: #{$gray-900};
//}

.header {
  z-index: 1030;
}

.is-invalid {
  border-color: #f74f78 !important;
}


.header a.nav-link {
  opacity: 0.75;

  &.active {
    color: $primary !important;
  }

  &:hover {
    opacity: 1
  }
}

.main-content {
  flex: 1 0 auto;
}

// Navbar
.medon-logo {
  color: $black;
  margin-top: -6px;
}

.navbar-light {
  .language-selector {
    border-color: rgba(0, 0, 0, 0.1) !important;

    &:hover {
      color: rgba(79, 65, 57, 0.65) !important;
      text-decoration: none !important;
    }
  }

  .navbar-logo {
    color: #463932;
  }
}

.navbar-dark {
  .language-selector {
    border-color: rgba(255, 255, 255, 0.4) !important;

    &:hover {
      color: rgba(255, 255, 255, 0.65) !important;
      text-decoration: none !important;
    }
  }

  .navbar-logo {
    color: $white;
  }
}


.language-selector {
  @extend .cs-tag;
  margin: 5px;

}

.page-heading {
  @extend .mb-4;
}

// Table list
.table-list-thumbnail-aware-header {
  margin-left: 92px; // Magick number to take thumbnail into account
}

.table-list-item {
  cursor: pointer;
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
  @extend .border-top;

  &:active {
    opacity: 0.6;
  }

  .table-list-item-thumbnail {
    @include media-breakpoint-down(sm) {
      margin-right: 0.5rem
    }
    @include media-breakpoint-up(sm) {
      margin-right: 1.5rem
    }
  }
}

.responsive-h3 {
  color: $gray-900;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    font-weight: 600
  }
  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
    font-weight: 600
  }
}

.font-size-sm-on-sm {
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-sm
  }
}

.whitespace-formatted-text {
  white-space: pre-line;
}

.table-list-without-header .table-list-item:first-child {
  border-top: 0 !important;
}

.doctor-th {
  @extend .py-2;

  .doctor-td {
    @extend .d-flex, .mb-2;
  }
}

.doctor-col-1 {
  flex-grow: 6;
}

.doctor-col-2 {
  flex-grow: 3;
}

.doctor-col-3 {
  flex-grow: 3;
}

.doctor-thumbnail {
  background-position: center center;
  background-size: cover;
  background-color: $gray-100;
}


.bg-nude-gradient {
  background: rgb(247, 241, 237);
  background: -moz-linear-gradient(343deg, rgba(247, 241, 237, 1) 0%, rgba(255, 232, 219, 1) 100%);
  background: -webkit-linear-gradient(343deg, rgba(247, 241, 237, 1) 0%, rgba(255, 232, 219, 1) 100%);
  background: linear-gradient(343deg, rgba(247, 241, 237, 1) 0%, rgba(255, 232, 219, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f1ed", endColorstr="#ffe8db", GradientType=1);

  //background: rgb(255,232,219);
  //background: linear-gradient(169deg, rgba(255,232,219,1) 0%, rgba(255,216,195,1) 100%);
}

$big-button-breakpoint: sm;

.big-button {
  @extend .btn, .btn-primary, .btn-lg;
}

@include media-breakpoint-up($big-button-breakpoint) {
  .big-button-container {
    .big-button-anchor:not(:first-child) {
      margin-left: $spacer;
    }
  }
}

@include media-breakpoint-down($big-button-breakpoint) {
  .big-button {
    width: 100%;
  }
  .big-button-anchor:not(:first-child) .big-button {
    margin-top: $spacer;
  }
}

.medium-button {
  @extend .btn, .btn-primary
}

.appointment-overview {
  @include media-breakpoint-down(md) {
    margin-top: 1.3rem !important;
    margin-bottom: 1.2rem !important;
  }

  @include media-breakpoint-up(md) {
    margin-top: 2.2rem !important;
    margin-bottom: 2rem !important;
  }
}

// Radio with image
.rwi {
  .rwi-input, .rwi-label-container {
    cursor: pointer;
  }
}

.patient-card, .mdn-form {
  label {
    font-weight: $font-weight-bold;
  }
}

.patient-appointment-card {
  p {
    @extend .text-muted;
  }
}


// Doctor page
.doctor-page-attributes {
  label {
    @extend .mb-1;
    color: $gray-700;
    font-weight: 600;
  }

  .attribute {
    @extend .mb-3
  }
}

.doctor-section {
  padding-top: 3.1rem;
  padding-bottom: 3.5rem;
}


// Patient dashboard
.patient-dashboard-topper {
  @include media-breakpoint-up(md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @include media-breakpoint-down(md) {
    padding-top: 1.7rem;
    padding-bottom: 1.4rem;
  }
}


// Doctor calendar
.doctor-calendar {
  .arrow {
    cursor: pointer;

    &.inactive {
      cursor: default !important;

      i {
        @extend .opacity-50;
      }
    }
  }

  .column {
    &.today {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  ul.schedule-times {
    border-radius: 0;

    &.loading {
      li {
        @extend .opacity-50;
      }
    }

    li:first-child {
      border-left: 0 !important;
      border-radius: 0 !important;
    }

    li:last-child {
      border-right: 0 !important;
      border-radius: 0 !important;
    }
  }


}

.flatpickr-wrapper {
  display: block;

  .form-control {
    background-color: #fff;
  }
}

// Col helpers
.col-squeezed-1 {
  @extend .col-12, .col-md-8, .offset-md-2, .col-xl-6, .offset-xl-3
}

.tooltip-icon {
  @extend .badge, .rounded-pill, .bg-secondary, .ms-1, .p-1;
  background-color: $gray-500 !important;
  color: $white !important;
  font-weight: 600;
}

.text-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-option-label {
  cursor: pointer !important;
  @extend .text-unselectable
}

ul {
  list-style: circle;
}

.custom-react-select {
  > div {
    border: 1px solid #dfdfeb;
    color: #04090C;
    border-radius: 0.75rem;
    padding-top: 0.19rem;
    padding-bottom: 0.19rem;
    padding-left: 0.25rem;
  }
}


.request-appointments-dows-container {
  .form-option-size label {
    width: auto;
    height: auto;
    line-height: 1.5rem;
    padding: 10px 20px;
  }
}

.form-option-size input:checked ~ label {
  border-color: rgb(16, 126, 173);
  background-color: lighten($primary, 65) !important;
  color: rgb(16, 126, 173);
}

.form-option-size input:disabled:not(:checked) ~ label {
  background-color: transparent !important;
  cursor: default !important;
  color: lighten($gray-500, 1) !important;
}

.form-option-size input:disabled:checked ~ label {
  border-color: $primary !important;
  //background-color: lighten($success, 48) !important;
  color: $primary !important;
  cursor: default !important;
}

.custom-checkbox-1 {
  flex-basis: 100%;

  label {
    //width: 5.5rem !important;
    width: 100% !important;
  }
}

// Auth/payment methods
.radio-col {
  @extend .col-6, .mb-4, .pb-2, .col-lg-3, .mb-lg-0
}

.navbar-box-shadow {
  box-shadow: 0 0.125rem 0.625rem -0.1875rem rgb(0 0 0 / 10%);
}

.navbar {
  .logo {
    @include media-breakpoint-up(md) {
      margin-right: 1.4em;
    }
    font-size: 25px;
  }

  &.navbar-stuck.navbar-dark {
    .logo {
      color: $black
    }
  }

  &.navbar-dark {
    .logo {
      color: $white
    }
  }
}

.language-badge {
  @extend .badge, .bg-secondary, .me-2;
  color: $gray-600 !important;
  font-weight: $font-weight-semibold;
}

.nav-link {
  white-space: nowrap;
}

.table > :not(:first-child) {
  border-top: 0;
}

.doctor-accordion {
  .accordion-button:not(.collapsed) {
    color: $gray-900
  }
}

.doctor-vertical-label-value {
  &:not(:last-child) {
    margin-bottom: $spacer * 0.75;
  }
}

.doctor-vertical-label-value-label {
  color: $gray-500;
  font-weight: $font-weight-semibold;
  font-size: $font-size-sm;
  //text-transform: uppercase;
}

.form-group {
  margin-bottom: $spacer * 0.75;
}

.huge-text {
  // TODO responsive
  font-size: 180px
}

.round-image-wrapper {
  @extend .d-flex, .justify-content-center, .align-items-center, .p-4;
  border-radius: 50%
}

.pt-45 {
  padding-top: $spacer * 2.35;
}

.blockquote {
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-top: 62px;
    text-align: center;
    &:before {
      left: 40%;
    }
  }
}

.text-normal {
  color: $body-color;
}

.preserve-whitespace {
  white-space: pre-line
}

.navbar-stuck {
  @extend .shadow
}

.bg-faded-primary-solid {
  background-color: mix($primary, $white, 10%)
}

.nav-link {
  @extend .fw-500;
}

.has-tip {
  border-bottom: 1px dashed $gray-500;
  cursor: help;
}

.navbar .navbar-nav .dropdown-menu .dropdown-divider {
  display: block;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

// Assumes that doctor profile picture ratio is 5x6
.doctor-image-container {
  width: 100%;
  height: 0;
  padding-top: 120%;
  position: relative;
}

.doctor-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sensitive-data, .hw-not-allow {
  // Special class to hide elements from mouseflow recordings
}

.table > :not(caption) > * > * {
  color: $body-color !important;
}

.inherit-fc {
  color: inherit !important;
}

.z-2 {
  z-index: 2;
}

.navbar-stuck {
  //box-shadow: none !important;
}

.navbar-stuck.bg-secondary {
  background-color: white !important;
}

.fancy-background {
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    //background-image:
    //        url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
    //background-image:
    //        url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%23FF4F2B' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%23FFA55C'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%23FF813D'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23FFCB63'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-image: url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%23FFA55C' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%23FFA55C'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%23B98556'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23E89B5A'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.step.active.inversed {
  .step-number {
    &:before {
      //background: none;
    }

    .step-number-inner {
      background: none;
      color: inherit
    }
  }
}

a:hover .hover-text-decoration-none {
  text-decoration: none !important;
}

.hover-text-decoration-none:hover {
  text-decoration: none !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  //text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  //display: flex;
  //justify-content: center;
  //align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.step.done .step-number-inner {
  color: $white !important;
  background-color: darken($success, 4%) !important;
}

.text-accent {
  color: #FF8F75;
}

.swiper-pagination-bullet {
  border: 0;
  background: $gray-300;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 0;
  background: $black;
}

.horizontal-flip {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(1, -1);
}

.request-appointment-vertical-split {
  width: 41.5%;
}

.video-container {
  position: relative;
}

.video-container1:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3));
}

// Video test
#background-video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: top;
  z-index: -2;
}

.swipable-steps .step:not(.last-step)::after {
  content: '' !important;
  // 20px is padding that react-swiper adds
  right: calc(var(--#{$prefix}steps-padding-y) * -1 - 20px) !important;
  width: calc(100% - var(--#{$prefix}steps-number-size) + 20px) !important;
}

.flex-full-height {
  flex: 1 0 auto !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $gray-200;
}

.separator:not(:empty)::before {
  margin-right: .75em;
}

.separator:not(:empty)::after {
  margin-left: .75em;
}

.otp-input {
  width: 40px !important;
  text-align: center;
}

.show-on-stuck {
  @include media-breakpoint-down(md) {
    display: none
  }
}

.navbar-stuck .show-on-stuck {
  @include media-breakpoint-down(md) {
    display: block
  }
}

.bg-faded-warning-solid {
  background-color: #FEF7F0;
}

.bg-secondary-solid {
  background-color: #EAF1F5
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-n1 {
  z-index: -1 !important;
}

.swiper-pagination-d-none .swiper-pagination {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.btn-check:checked + .btn {
  background: $secondary;
}

.bg-indian-flag {
  background: rgb(250, 193, 172);
  background: linear-gradient(90deg, rgba(250, 193, 172, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(105, 167, 136, 1) 100%);
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}

.bg-new-green {
  background-color: #6D7342
}

.dark-content {
  color: lighten(#09151A, 75%);

  h1, h2, h3, h4, h5, h6 {
    color: lighten(#09151A, 90%)
  }
}

.btn-romantic {
  background: #EA3D53;

  &:hover {
    background: darken(#EA3D53, 10);
    color: $white;
  }
}