.doctor-call-row {
  min-height: 0;
  align-content: flex-start;
  @include media-breakpoint-up(lg) {
    height: 100vh; // https://stackoverflow.com/questions/19119910/safari-height-100-element-inside-a-max-height-element
  }
}

.doctor-call-daily-container {
  padding-left: 0.6rem !important;

  @include media-breakpoint-up(lg) {
    height: 100% !important;
  }
  @include media-breakpoint-down(lg) {
    min-height: 400px !important;
    max-height: 500px !important;
    padding-right: 0.9rem !important;
  }
}

.doctor-call-sidebar {
  @include media-breakpoint-up(lg) {
    overflow-y: scroll;
  }
  @include media-breakpoint-down(lg) {
    overflow-y: visible;
  }
}
