.disabled-cell {
  pointer-events: none;
  opacity: 0.6;
}

.pointer-if-enabled:not(.disabled) {
  cursor: pointer !important;
}

.form-row {
  @extend .mb-2
}