@import "../around/theme";

.noUi-target {
  background: darken($secondary, 2) !important;
  border-radius: 4px !important;
  border: 0 !important;
  box-shadow: none !important;
}

.noUi-handle {
  width: 21px !important;
  height: 21px !important;
  border: 0 !important;
  right: -11px !important;
  border-radius: 15px !important;
  background: $primary !important;
  cursor: pointer !important;
  box-shadow: none !important;
}

.noUi-handle:before, .noUi-handle:after {
  background: transparent !important;
}

.noUi-horizontal {
  height: 10px !important;
}

.noUi-connect {
  background: lighten($primary, 40) !important;
}

.noUi-target[disabled=true] {
  .noUi-handle {
    width: 4px !important;
    height: 21px !important;
    border: 0 !important;
    right: -1px !important;
    background: $primary !important;
    cursor: pointer !important;
    box-shadow: none !important;
  }
}

.noUi-tooltip {
  padding: 5px 14px !important;
  color: #607178 !important;
  font-size: 0.9375rem !important;
  border: 0 !important;
}

.noUi-value {
  @extend .text-muted;
  font-size: 10px !important;
}

.noUi-marker, .noUi-value {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
