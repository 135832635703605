.footer.footer-light {
  @extend .bg-secondary;
  .logo {
    color: $dark;
  }
  a {
    color: $dark;
  }
  .social-button {
    @extend .btn-dark;
  }
}

.footer.footer-dark {
  @extend .bg-dark;
  color: $gray-400;

  .logo {
    color: $white;
  }
  h6 {
    color: $white;
  }
  a {
    color: $gray-400;
  }
  .social-button {
    @extend .btn-secondary, .text-dark;
  }
}
